/*
* システム管理者 管理画面
*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

body {
  font-family: 'Noto Sans JP', sans-serif !important;
}


/*
* reboot.css 上書き
*/
label {
  margin-bottom: 0 !important;
}

.mainContent {
  padding-top: 1.6rem;
}

ul {
  &:last-of-type {
    margin-bottom: 0;
  }
}

/*
* bootstrap上書き
*/
.card-header {
  h3 {
    margin-bottom: 0;
  }
}

/*
* インデント
*/
@for $i from 1 through 10 {
  .padIndent-#{$i} {
    padding-left: 1.6rem;
  }
}

/*
* 表示固定ヘッダー
*/
.fixedHeader {
  position: sticky;
  top: 0;
  background-color: #f7f7f7;
  z-index: 999;
}

/*
* 下部固定保存ボタン
*/
.fixedButtons {
  position: sticky;
  bottom: 0;
  background-color: #f7f7f7;
  z-index: 999;
}

/*
* 画像アップロード確認用
*/
.wrapPreviewImage {
  position: relative;
  margin-bottom: .25rem !important;

  .previewImage {
    padding: 4px;
    border: 1px solid #dcdddd;
    border-radius: 4px;
  }

  .btnDeleteFile {
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px;
    font-size: 1.6rem;
    line-height: 0.96;
    background-color: #FFFFFF;
    border: 1px solid #dc3545;
    border-radius: 50%;
  }
}

/*
* 必須
*/
.required-badge {
  &::after {
    content: url(../../images/icon-required.svg);
    display: inline-block;
    position: relative;
    top: 3px;
    right: -4px;
    width: 34px;
    height: auto;
  }
}

/*
* マルチSelect（料理ジャンル）
*/
.selectDepth {
  &-1 {
    padding-left: 1.6rem !important;
  }
}

/*
* タグ
*/
.tokenfield {
  .token {
    .token-label {
      line-height: 1.2;
    }

    .close {
      line-height: 1.4rem;
    }
  }
}

/*
* タグ入力用（Tagify）
*/
.tagify {
  height: auto !important;
}
.tags-look {
  .tagify__dropdown__item {
    display: inline-block;
    border-radius: 3px;
    padding: .3em .5em;
    border: 1px solid #CCC;
    background: #F3F3F3;
    margin: .2em;
    font-size: .85em;
    color: black;
    transition: 0s;
  }

  .tagify__dropdown__item--active {
    color: black;
  }

  .tagify__dropdown__item:hover {
    background: lightyellow;
    border-color: gold;
  }
}

/*
* fieldset
*/
.admin-fieldset {
  padding: 8px;
  border: 1px solid #dadada;
  border-radius: 4px;
  legend {
    display: inline-block;
    margin: 0 auto 0 8px;
    padding: 0 4px;
    width: auto;
    font-size: clamp(0.8rem, 0.3rem + 0.91vw, 1rem);
  }
}

/*
* 一覧検索
*/
fieldset.searchList {
  margin-bottom: 1.6rem;
  padding: 0 0.8rem 0.8rem 0.8rem;
  border: 1px solid #e2e2e2;
  legend {
    display: inline-block;
    margin-left: 0.8rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    width: auto;
    font-size: 1.0rem;
  }
}

/*
* 店舗検索
*/
#shop-search-modal {

  /*
  * 検索結果
  */
  .search-result-area {
    figure {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      max-height: 72px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
  }
}

/*
* フェスティバル追加店舗
*/
.section-festival-area {
  .shop-lists {
    display: grid;
    grid-template-columns: repeat(5, minmax(200px, 1fr));
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    margin: 0 auto;
    padding: 8px 0 0 0;
    list-style-type: none;
    .shop {
      position: relative;
      padding: 8px;
      background-color: #f7f7f7;
      border: 1px solid #dedede;
      border-radius: 2px;
      cursor: grab;
      &.ui-sortable-helper {
        background-color: #cccccc;
      }
      figure {
        margin: 0 auto 4px auto;
        width: 100%;
        height: 128px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .button-shop-delete {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 24px;
        height: 24px;
        font-size: 14px;
        line-height: 1.4;
        border: 1px solid #a2a2a2;
      }
    }
  }
}

/*
* Drag and Drop ハンドル
*/
.handle {
  cursor: grab;
  cursor: hand;
}
.tDnD_whileDrag {
  background-color: #eaeaea;
}

/*
* 店舗ジャンル
*/
#genres {
  column-gap: 4px;
  .shop-genre {
    column-gap: 4px;
    padding: 8px 16px 8px 8px;
    border: 1px solid rgba(0, 0, 0, 0.124);
  }

}
